
[id=google-map-page] {
    background-color: white;
}

[id=google-map-container-wrapper] {
    width : 100%;
}

[id=google-map-container] {
    height: 100%;
    width : 100%;
}

.map-loader {
    display: flex;
    height : 100%;
    width  : 100%;
    justify-content : center;

    div {
        display: flex;
        flex-direction: column;
        justify-content : center;
        height: 100%;
    }

    ons-progress-circular {
        width : 100px !important;
        height: 100px !important;
    }
}

[id=g-map] {
    //height: 100vh;
    width : 100%;
}

.gmap-buttons {
    text-align: center;
    display: flex;
    height: 150px;
    background: white;
    flex-direction: column;
    padding: 20px 10vw;
}
